import popup from '../css/popup.module.css'
function PopupComponent({ item, onClose }) {
    return (
        <div className={popup.popup}>
            <div className={popup.popup__container}>
                <button className={popup.popup__close} onClick={onClose}>
                    close
                </button>
                <span>{item.name}</span>
                <div className={popup.list}>
                    {item.options.map((option, index) => (
                        <span key={index}>{option}</span>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default PopupComponent;