export default function SelectorItem({item, onClick}) {

    return (
        <div className={`solutions__wrapper ${item.id}`} key={item.id} onClick={() => onClick(item)}>
            <div className="solutions__info">
                <span className="solutions__name">{item.name}</span>
                <div className="solutions__options">
                    {item.options.length > 0 ? (
                        item.options.map((option) => (
                            <span>{option}</span>
                        ))
                    ): (
                        <p>empty</p>
                    )}
                </div>
            </div>
            <div className="solution">
                {item.images.length > 0 ? (
                    item.images.map((img) => (
                        <img key={img.class} src={img.img} className={img.class} alt="img"/>
                    ))
                ): (
                    <p>empty</p>
                )}
            </div>
        </div>
    )
}