import fuelStorage from '../assets/imgs/fuel_storage.png'
import hydrolicsStocket from '../assets/imgs/hydralicstoker_loader.png'
import k8 from '../assets/imgs/k8.png'
import eco from '../assets/imgs/eco.png'
import multiCiclone from '../assets/imgs/multi-cyclone.png'
import luvo from '../assets/imgs/luvo.png'
import wwnone from '../assets/imgs/ww-none.png'
import hwnone from '../assets/imgs/hw-none.png'
import sd from '../assets/imgs/sd.png'
import screwLoader from '../assets/imgs/screw-loader.png';
import silo from '../assets/imgs/silo.png'
import screwStocker from '../assets/imgs/skrew-stoker.png'
import hdddr from '../assets/imgs/hdd_rr.png'
import k12 from '../assets/imgs/k12.png'
import chainLoader from '../assets/imgs/chainloader.png'
import stoker from  '../assets/imgs/stoker.png'
import to from '../assets/imgs/to.png'
import hddwr from '../assets/imgs/hdd_wr.png'
import k13 from '../assets/imgs/k13.png'
import k15 from '../assets/imgs/k15.png'
import k16 from '../assets/imgs/k16.png'
import turbo_generator from '../assets/imgs/icons/sg.svg'
import quadro from '../assets/imgs/icons/quadro.svg'
export const selectors = [
    {
        name: 'K8-WW',
        id:"k8ww",
        options: ['quality wood chips', 'hot water', 'forestry residues', 'wood industry residues'],
        images: [
            {
                class: 'first',
                img: fuelStorage
            },
            {
                class: 'two',
                img: hydrolicsStocket
            },
            {
                class: 'third',
                img: wwnone,
            },
            {
                class: 'four',
                img: k8
            },
            {
                class: 'five disabled',
                img: eco
            },
            {
                class: 'six',
                img: multiCiclone
            },
            {
                class: 'seven',
                img: luvo
            }
        ]
    },
    {
        name: 'k8-HW',
        id:"k8hw",
        options: ['quality wood chips', 'superheated water', 'forestry residues', 'wood industry residues'],
        images: [
            {
                class: 'first',
                img: fuelStorage
            },
            {
                class: 'two',
                img: hydrolicsStocket
            },
            {
                class: 'third',
                img: hwnone,
            },
            {
                class: 'four',
                img: k8
            },
            {
                class: 'five',
                img: eco
            },
            {
                class: 'six',
                img: multiCiclone
            },
            {
                class: 'seven',
                img: luvo
            }
        ]
    },
    {
        name: 'K8-SD',
        id:"k8sd",
        options: ['process steam', 'quality wood chips', 'forestry residues', 'wood industry residues'],
        images: [
            {
                class: 'first',
                img: fuelStorage
            },
            {
                class: 'two',
                img: hydrolicsStocket
            },
            {
                class: 'third sd',
                img: sd,
            },
            {
                class: 'four',
                img: k8
            },
            {
                class: 'five',
                img: eco
            },
            {
                class: 'six',
                img: multiCiclone
            },
            {
                class: 'seven',
                img: luvo
            }
        ]
    },
    {
        name: 'K8-SDG',
        id:"k8sdg",
        options: ['electric energy', 'quality wood chips', 'forestry residues', 'wood industry residues'],
        images: [
            {
                class: 'first',
                img: fuelStorage
            },
            {
                class: 'two',
                img: hydrolicsStocket
            },
            {
                class: 'third sd',
                img: sd,
            },
            {
                class: 'four',
                img: k8
            },
            {
                class: 'five',
                img: eco
            },
            {
                class: 'six',
                img: multiCiclone
            },
            {
                class: 'seven',
                img: luvo
            },
        ]
    },
    {
        name: 'K8-HDDRR',
        id:"k8hddrr",
        options: ['electric energy', 'quality wood chips', 'forestry residues', 'wood industry residues'],
        images: [
            {
                class: 'first',
                img: fuelStorage
            },
            {
                class: 'two',
                img: hydrolicsStocket
            },
            {
                class: 'third',
                img: hdddr,
            },
            {
                class: 'four',
                img: k8
            },
            {
                class: 'five',
                img: eco
            },
            {
                class: 'six',
                img: multiCiclone
            },
            {
                class: 'seven',
                img: luvo
            },
            {
                class: 'generator',
                img: turbo_generator,
            },
        ]
    },
    {
        name: 'K8s',
        id:"k8s",
        options: ['used wood'],
        images: [
            {
                class: 'first k8s',
                img: silo
            },
            {
                class: 'two k8s',
                img: screwLoader
            },
            {
                class: 'third k8s',
                img: hwnone,
            },
            {
                class: 'four k8s',
                img: k8
            },
            {
                class: 'five k8s disabled',
                img: eco
            },
            {
                class: 'six k8s',
                img: multiCiclone
            },
            {
                class: 'seven k8s',
                img: luvo
            },
            {
                class: 'eight k8s',
                img: screwStocker
            },
        ]
    },
    {
        name: 'K8s-HW',
        id:"k8shw",
        options: ['used wood'],
        images: [
            {
                class: 'first k8s',
                img: silo
            },
            {
                class: 'two k8s',
                img: screwLoader
            },
            {
                class: 'third k8s',
                img: hwnone,
            },
            {
                class: 'four k8s',
                img: k8
            },
            {
                class: 'five k8s',
                img: eco
            },
            {
                class: 'six k8s',
                img: multiCiclone
            },
            {
                class: 'seven k8s',
                img: luvo
            },
            {
                class: 'eight k8s',
                img: screwStocker
            },
        ]
    },
    {
        name: 'K8s-SD',
        id:"k8ssd",
        options: ['used wood'],
        images: [
            {
                class: 'first k8s',
                img: silo
            },
            {
                class: 'two k8s',
                img: screwLoader
            },
            {
                class: 'third k8s sd',
                img: sd,
            },
            {
                class: 'four k8s',
                img: k8
            },
            {
                class: 'five k8s ',
                img: eco
            },
            {
                class: 'six k8s',
                img: multiCiclone
            },
            {
                class: 'seven k8s',
                img: luvo
            },
            {
                class: 'eight k8s',
                img: screwStocker
            },
        ]
    },
    {
        name: 'K8s-SDG',
        id:"k8sSdg",
        options: ['used wood'],
        images: [
            {
                class: 'first k8s',
                img: silo
            },
            {
                class: 'two k8s',
                img: screwLoader
            },
            {
                class: 'third k8s sd',
                img: sd,
            },
            {
                class: 'four k8s',
                img: k8
            },
            {
                class: 'five k8s ',
                img: eco
            },
            {
                class: 'six k8s',
                img: multiCiclone
            },
            {
                class: 'seven k8s',
                img: luvo
            },
            {
                class: 'eight k8s',
                img: screwStocker
            },
            {
                class: 'generator',
                img: turbo_generator,
            },
        ]
    },
    {
        name: 'K8s-HDDRR',
        id:"k8shddrr",
        options: ['used wood'],
        images: [
            {
                class: 'first k8s',
                img: silo
            },
            {
                class: 'two k8s',
                img: screwLoader
            },
            {
                class: 'third k8s hdddr',
                img: hdddr,
            },
            {
                class: 'four k8s',
                img: k8
            },
            {
                class: 'five k8s ',
                img: eco
            },
            {
                class: 'six k8s',
                img: multiCiclone
            },
            {
                class: 'seven k8s',
                img: luvo
            },
            {
                class: 'eight k8s',
                img: screwStocker
            },
            {
                class: 'generator',
                img: turbo_generator,
            },
        ]
    },
    {
        name: 'K12-WW',
        id:"k12",
        options: ['quality wood chips', 'hot water', 'forestry residues', 'wood industry residues'],
        images: [
            {
                class: 'first k12',
                img: fuelStorage
            },
            {
                class: 'two k12',
                img: chainLoader
            },
            {
                class: 'third k12',
                img: wwnone,
            },
            {
                class: 'four k12',
                img: k12
            },
            {
                class: 'five k12 disabled',
                img: eco
            },
            {
                class: 'six k12',
                img: multiCiclone
            },
            {
                class: 'seven k12',
                img: luvo
            },
            {
                class: 'eight k12 k8s',
                img: stoker
            },
        ]
    },
    {
        name: 'K12-HW',
        id:"k12hw",
        options: ['quality wood chips', 'superheated water', 'forestry residues', 'wood industry residues'],
        images: [
            {
                class: 'first k12',
                img: fuelStorage
            },
            {
                class: 'two k12',
                img: chainLoader
            },
            {
                class: 'third k12',
                img: hwnone,
            },
            {
                class: 'four k12',
                img: k12
            },
            {
                class: 'five k12',
                img: eco
            },
            {
                class: 'six k12',
                img: multiCiclone
            },
            {
                class: 'seven k12',
                img: luvo
            },
            {
                class: 'eight k12 k8s',
                img: stoker
            },
        ]
    },
    {
        name: 'K12-Sd',
        id:"k12sd",
        options: ['process steam', 'quality wood chips', 'forestry residues', 'wood industry residues'],
        images: [
            {
                class: 'first k12',
                img: fuelStorage
            },
            {
                class: 'two k12',
                img: chainLoader
            },
            {
                class: 'third k12',
                img: sd,
            },
            {
                class: 'four k12',
                img: k12
            },
            {
                class: 'five k12',
                img: eco
            },
            {
                class: 'six k12',
                img: multiCiclone
            },
            {
                class: 'seven k12',
                img: luvo
            },
            {
                class: 'eight k12 k8s',
                img: stoker
            },
        ]
    },
    {
        name: 'K12-TO',
        id:"k12to",
        options: ['quality wood chips', 'thermail oil', 'forestry residues', 'wood industry residues'],
        images: [
            {
                class: 'first k12',
                img: fuelStorage
            },
            {
                class: 'two k12',
                img: chainLoader
            },
            {
                class: 'third k12',
                img: to,
            },
            {
                class: 'four k12',
                img: k12
            },
            {
                class: 'five k12',
                img: eco
            },
            {
                class: 'six k12',
                img: multiCiclone
            },
            {
                class: 'seven k12',
                img: luvo
            },
            {
                class: 'eight k12 k8s',
                img: stoker
            },
        ]
    },
    {
        name: 'K12-SD-GO',
        id:"k12G",
        options: ['electric energy', 'quality wood chips', 'forestry residues', 'wood industry residues'],
        images: [
            {
                class: 'first k12',
                img: fuelStorage
            },
            {
                class: 'two k12',
                img: chainLoader
            },
            {
                class: 'third k12 sd',
                img: sd,
            },
            {
                class: 'four k12',
                img: k12
            },
            {
                class: 'five k12',
                img: eco
            },
            {
                class: 'six k12',
                img: multiCiclone
            },
            {
                class: 'seven k12',
                img: luvo
            },
            {
                class: 'eight k12 k8s',
                img: stoker
            },
            {
                class: 'generator',
                img: turbo_generator,
            },
        ]
    },
    {
        name: 'K12-HDD-RR',
        id:"k12hddrr",
        options: ['electric energy', 'quality wood chips', 'forestry residues', 'wood industry residues'],
        images: [
            {
                class: 'first k12',
                img: fuelStorage
            },
            {
                class: 'two k12',
                img: chainLoader
            },
            {
                class: 'third k12',
                img: hdddr,
            },
            {
                class: 'four k12',
                img: k12
            },
            {
                class: 'five k12',
                img: eco
            },
            {
                class: 'six k12',
                img: multiCiclone
            },
            {
                class: 'seven k12',
                img: luvo
            },
            {
                class: 'eight k12 k8s',
                img: stoker
            },
            {
                class: 'generator',
                img: turbo_generator,
            },
        ]
    },
    {
        name: 'K12-HDD-WR',
        id:"k12hddwr",
        options: ['electric energy', 'quality wood chips', 'forestry residues', 'wood industry residues'],
        images: [
            {
                class: 'first k12',
                img: fuelStorage
            },
            {
                class: 'two k12',
                img: chainLoader
            },
            {
                class: 'third k12',
                img: hddwr,
            },
            {
                class: 'four k12',
                img: k12
            },
            {
                class: 'five k12',
                img: eco
            },
            {
                class: 'six k12',
                img: multiCiclone
            },
            {
                class: 'seven k12',
                img: luvo
            },
            {
                class: 'eight k12 k8s',
                img: stoker
            },
            {
                class: 'generator',
                img: turbo_generator,
            },
        ]
    },
    {
        name: 'K12-TO-G',
        id:"k12tog",
        options: ['electric energy', 'quality wood chips', 'forestry residues', 'wood industry residues'],
        images: [
            {
                class: 'first k12',
                img: fuelStorage
            },
            {
                class: 'two k12',
                img: chainLoader
            },
            {
                class: 'third k12',
                img: to,
            },
            {
                class: 'four k12',
                img: k12
            },
            {
                class: 'five k12',
                img: eco
            },
            {
                class: 'six k12',
                img: multiCiclone
            },
            {
                class: 'seven k12',
                img: luvo
            },
            {
                class: 'eight k12 k8s',
                img: stoker
            },
            {
                class: 'generator',
                img: turbo_generator,
            },
            {
                class: 'quadro-1',
                img: quadro,
            },
            {
                class: 'quadro-2',
                img: quadro,
            },
        ]
    },
    {
        name: 'K13-WW',
        id:"k13ww",
        options: ['wood pellets', 'hot water', 'quality wood chips', 'wood industry residues'],
        images: [
            {
                class: 'k8s first',
                img: silo
            },
            {
                class: 'k8s two ',
                img: screwLoader
            },
            {
                class: 'third k13',
                img: wwnone,
            },
            {
                class: 'four',
                img: k13
            },
            {
                class: 'five disabled',
                img: eco
            },
            {
                class: 'six',
                img: multiCiclone
            },
            {
                class: 'seven',
                img: luvo
            },
            {
                class: 'eight k8s',
                img: screwStocker
            },
        ]
    },
    {
        name: 'K13-HW',
        id:"k13hw",
        options: ['wood pellets', 'superheated water', 'quality wood chips', 'wood industry residues'],
        images: [
            {
                class: 'k8s first',
                img: silo
            },
            {
                class: 'k8s two ',
                img: screwLoader
            },
            {
                class: 'third k13',
                img: hwnone,
            },
            {
                class: 'four',
                img: k13
            },
            {
                class: 'five ',
                img: eco
            },
            {
                class: 'six',
                img: multiCiclone
            },
            {
                class: 'seven',
                img: luvo
            },
            {
                class: 'eight k8s',
                img: screwStocker
            },
        ]
    },
    {
        name: 'K13-SD',
        id:"k13sd",
        options: ['wood pellets', 'process steam', 'quality wood chips', 'electric energy', 'wood industry residues'],
        images: [
            {
                class: 'k8s first',
                img: silo
            },
            {
                class: 'k8s two ',
                img: screwLoader
            },
            {
                class: 'third k13 sd',
                img: sd,
            },
            {
                class: 'four',
                img: k13
            },
            {
                class: 'five ',
                img: eco
            },
            {
                class: 'six',
                img: multiCiclone
            },
            {
                class: 'seven',
                img: luvo
            },
            {
                class: 'eight k8s',
                img: screwStocker
            },
        ]
    },
    {
        name: 'K13-SDG',
        id:"k13sdg",
        options: ['used wood', 'wood industry residues'],
        images: [
            {
                class: 'k8s first',
                img: silo
            },
            {
                class: 'k8s two ',
                img: screwLoader
            },
            {
                class: 'third k13 sd',
                img: sd,
            },
            {
                class: 'four',
                img: k13
            },
            {
                class: 'five ',
                img: eco
            },
            {
                class: 'six',
                img: multiCiclone
            },
            {
                class: 'seven',
                img: luvo
            },
            {
                class: 'eight k8s',
                img: screwStocker
            },
            {
                class: 'generator',
                img: turbo_generator,
            },
        ]
    },
    {
        name: 'K13-HDDRR',
        id:"k13hddrr",
        options: ['electric energy', 'quality wood chips', 'wood industry residues'],
        images: [
            {
                class: 'k8s first',
                img: silo
            },
            {
                class: 'k8s two ',
                img: screwLoader
            },
            {
                class: 'third',
                img: hdddr,
            },
            {
                class: 'four',
                img: k13
            },
            {
                class: 'five ',
                img: eco
            },
            {
                class: 'six',
                img: multiCiclone
            },
            {
                class: 'seven',
                img: luvo
            },
            {
                class: 'eight k8s',
                img: screwStocker
            },
            {
                class: 'generator',
                img: turbo_generator,
            },
        ]
    },
    {
        name: 'K15-WW',
        id:"k15ww",
        options: ['used wood', 'hot water', 'wood industry residues'],
        images: [
            {
                class: 'first',
                img: fuelStorage
            },
            {
                class: 'two ',
                img: chainLoader
            },
            {
                class: 'third',
                img: wwnone,
            },
            {
                class: 'four',
                img: k15
            },
            {
                class: 'five disabled',
                img: eco
            },
            {
                class: 'six',
                img: multiCiclone
            },
            {
                class: 'seven',
                img: luvo
            },
            {
                class: 'eight k8s',
                img: stoker
            },
        ]
    },
    {
        name: 'K15-HW',
        id:"k15hw",
        options: ['used wood', 'superheated water', 'wood industry residues'],
        images: [
            {
                class: 'first',
                img: fuelStorage
            },
            {
                class: 'two ',
                img: chainLoader
            },
            {
                class: 'third',
                img: hwnone,
            },
            {
                class: 'four',
                img: k15
            },
            {
                class: 'five disabled',
                img: eco
            },
            {
                class: 'six',
                img: multiCiclone
            },
            {
                class: 'seven',
                img: luvo
            },
            {
                class: 'eight k8s',
                img: stoker
            },
        ]
    },
    {
        name: 'K15-SD',
        id:"k15sd",
        options: ['used wood', 'process steam', 'wood industry residues'],
        images: [
            {
                class: 'first',
                img: fuelStorage
            },
            {
                class: 'two ',
                img: chainLoader
            },
            {
                class: 'third sd ',
                img: sd,
            },
            {
                class: 'four',
                img: k15
            },
            {
                class: 'five ',
                img: eco
            },
            {
                class: 'six',
                img: multiCiclone
            },
            {
                class: 'seven',
                img: luvo
            },
            {
                class: 'eight k8s',
                img: stoker
            },
        ]
    },
    {
        name: 'K15-sdg',
        id:"k15sdg",
        options: ['used wood', 'electric energy', 'wood industry residues'],
        images: [
            {
                class: 'first',
                img: fuelStorage
            },
            {
                class: 'two ',
                img: chainLoader
            },
            {
                class: 'third sd',
                img: sd,
            },
            {
                class: 'four',
                img: k15
            },
            {
                class: 'five ',
                img: eco
            },
            {
                class: 'six',
                img: multiCiclone
            },
            {
                class: 'seven',
                img: luvo
            },
            {
                class: 'eight k8s',
                img: stoker
            },
            {
                class: 'generator',
                img: turbo_generator,
            },
        ]
    },
    {
        name: 'K15-HDDRR',
        id:"k15hddrr",
        options: ['used wood', 'electric energy'],
        images: [
            {
                class: 'first',
                img: fuelStorage
            },
            {
                class: 'two ',
                img: chainLoader
            },
            {
                class: 'third sd',
                img: hdddr,
            },
            {
                class: 'four',
                img: k15
            },
            {
                class: 'five ',
                img: eco
            },
            {
                class: 'six',
                img: multiCiclone
            },
            {
                class: 'seven',
                img: luvo
            },
            {
                class: 'eight k8s',
                img: stoker
            },
            {
                class: 'generator',
                img: turbo_generator,
            },
        ]
    },
    {
        name: 'K16-WW',
        id:"k16ww",
        options: ['used wood', 'hot water', 'wood industry residues'],
        images: [
            {
                class: 'first',
                img: fuelStorage
            },
            {
                class: 'two ',
                img: chainLoader
            },
            {
                class: ' k16 third sd',
                img: wwnone,
            },
            {
                class: 'four k16',
                img: k16
            },
            {
                class: 'five ',
                img: eco
            },
            {
                class: 'six',
                img: multiCiclone
            },
            {
                class: 'seven',
                img: luvo
            },
            {
                class: 'eight k8s',
                img: stoker
            },
        ]
    },
    {
        name: 'K16-HW',
        id:"k16hw",
        options: ['used wood', 'superheated water', 'wood industry residues'],
        images: [
            {
                class: 'first',
                img: fuelStorage
            },
            {
                class: 'two ',
                img: chainLoader
            },
            {
                class: 'k16 third sd',
                img: hwnone,
            },
            {
                class: 'four k16',
                img: k16
            },
            {
                class: 'five ',
                img: eco
            },
            {
                class: 'six',
                img: multiCiclone
            },
            {
                class: 'seven',
                img: luvo
            },
            {
                class: 'eight k8s',
                img: stoker
            },
        ]
    },
    {
        name: 'K16-SD',
        id:"k16sd",
        options: ['used wood', 'process steam', 'wood industry residues'],
        images: [
            {
                class: 'first',
                img: fuelStorage
            },
            {
                class: 'two ',
                img: chainLoader
            },
            {
                class: 'k16 third sd',
                img: sd,
            },
            {
                class: 'four k16',
                img: k16
            },
            {
                class: 'five ',
                img: eco
            },
            {
                class: 'six',
                img: multiCiclone
            },
            {
                class: 'seven',
                img: luvo
            },
            {
                class: 'eight k8s',
                img: stoker
            },
        ]
    },
]