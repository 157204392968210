import {useEffect, useReducer, useState} from "react";
import FilterComponent from "./components/Filter";
import {fuelsFilter, energyFilters, industryFilters, applicationFilters} from "./utils/filtersNames";
import SelectorItem from "./components/Selector";
import {selectors} from "./utils/selectors";
import style from './css/filters.module.css'
import PopupComponent from "./components/Popup";
import solutions from './css/items.module.css'

function App() {
    const allItems = selectors;

    const [selectedFilters, setSelectedFilters] = useState([]);
    const [selectedElement, setSelectedElement] = useState(null);

    const handleFilterChange = (filter) => {
        const updatedFilters = selectedFilters.includes(filter)
            ? selectedFilters.filter((f) => f !== filter)
            : [...selectedFilters, filter];

        setSelectedFilters(updatedFilters);
        setSelectedElement(null)
    };

    const filteredItems = allItems.filter((item) =>
        selectedFilters.every((filter) => item.options.includes(filter))
    );
    const handleElementClick = (element) => {
        setSelectedElement(element);
        console.log(123)
    };

    return (
    <div className="page container">
        <header className={style.header}>
            <span className={style.header__span}>Selector</span>
        </header>
      <div className={style.filters__wrapper}>
          <span className={style.filters__wrapper__title}>FILTERS</span>
          <div className={style.filters__wrapper__categories}>
              <span>fuels</span>
              <span>energy</span>
              <span>industry</span>
              <span>application</span>
          </div>
          <div className={style.filters__box}>
          <FilterComponent
              filters={fuelsFilter}
              selectedFilters={selectedFilters}
              onFilterChange={handleFilterChange}
              filteredItems={filteredItems}
          />
          <FilterComponent
              filters={energyFilters}
              selectedFilters={selectedFilters}
              onFilterChange={handleFilterChange}
              filteredItems={filteredItems}
          />
          <FilterComponent
              filters={industryFilters}
              selectedFilters={selectedFilters}
              onFilterChange={handleFilterChange}
              filteredItems={filteredItems}
          />
          <FilterComponent
              filters={applicationFilters}
              selectedFilters={selectedFilters}
              onFilterChange={handleFilterChange}
              filteredItems={filteredItems}
          />
          </div>
      </div>
        <div className={solutions.solutions}>
            {filteredItems.length > 0 ? (
                filteredItems.map((item, index) => (
                    <SelectorItem key={item.id} item={item} onClick={() => handleElementClick(item)}/>
                ))
            ) : (
                <p className={style.empty}>no match</p>
            )}
        </div>
        {selectedElement && (
            <PopupComponent
                item={selectedElement}
                onClose={() => setSelectedElement(null)}
            />
        )}
    </div>
  );
}

export default App;
