import React, { useState } from 'react';
import styles from '../css/filters.module.css';

function FilterComponent({ filters, selectedFilters, onFilterChange, filteredItems }) {

    return (
        <div className={styles.filters}>
            {filters.map((filter) => {
                const isActive = selectedFilters.includes(filter.name);
                const isDisabled = !isActive && !filteredItems.some((item) => item.options.includes(filter.name));
                return (
                    <label key={filter.name} className={`${styles.filter} ${filter.class} ${isActive ? styles.active : ''} ${isDisabled ? 'disabled' : ''}`}>
                        <input
                            type="checkbox"
                            value={filter.name}
                            checked={isActive}
                            disabled={isDisabled}
                            onChange={() => onFilterChange(filter.name)}
                        />
                        <span>{filter.name}</span>
                        <span className={styles.check}></span>
                    </label>
                )
            })}
        </div>
    );
}

export default FilterComponent;