export const fuelsFilter = [
    {
        name: 'forestry residues',
        class: 'filter fr'
    },
    {
        name: 'wood industry residues',
        class: 'filter wir'
    },
    {
        name: 'quality wood chips',
        class: 'filter qwc'
    },
    {
        name: 'wood pellets',
        class: 'filter wp'
    },
    {
        name: 'used wood',
        class: 'filter uw'
    },
]
export const energyFilters = [
    {
        name: 'hot water',
        class: 'filter hw'
    },
    {
        name: 'superheated water',
        class: 'filter sw'
    },
    {
        name: 'process steam',
        class: 'filter ps'
    },
    {
        name: 'electric energy',
        class: 'filter ee'
    },
    {
        name: 'thermail oil',
        class: 'filter oil'
    },
]


export const industryFilters = [

    {
        name: 'medicine',
        class: 'filter m'
    },
    {
        name: 'agro',
        class: 'filter a'
    },
    {
        name: 'hotels',
        class: 'filter h'
    },
    {
        name: 'sawmill',
        class: 'filter s'
    },
    {
        name: 'remote area',
        class: 'filter ra'
    },
]
export const applicationFilters = [
    {
        name: 'heat supply',
        class: 'filter hs'
    },
    {
        name: 'steam supply',
        class: 'filter ss'
    },
    {
        name: 'electric supply',
        class: 'filter es'
    },
    {
        name: 'cogeneration',
        class: 'filter c'
    },
]